import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { content } from "content/design-services"
import { Hero, Main, OurClients, WebDesign, AppDesign, SocialMedia, AtomicDesign } from "components"

const DesignServices = () => {
	const homeHero = useStaticQuery(graphql`
		query homeHeros {
			file(relativePath: { eq: "hero_desgin_services.png" }) {
				relativePath
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	return (
		<Main>
			<Hero
				img={homeHero}
				title={content.hero.title}
				text={content.hero.text}
				buttonText={content.hero.button.text}
				buttonLink={content.hero.button.path}
			/>
			<WebDesign
				title={content.WebApp.title}
				text={content.WebApp.text}
				image1={content.WebApp.image}
				image2={content.WebApp.image2}
				image3={content.WebApp.image3}
			/>
			<AppDesign title={content.MobileApp.title} text={content.MobileApp.text} picture={content.MobileApp.image} />
			<OurClients title={content.LogoFolio.title} />
			<SocialMedia />
			<AtomicDesign />
		</Main>
	)
}

export default DesignServices
